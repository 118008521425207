#sfu-carousel {
  width: 100%;
  height: 550px;
  padding: 2rem 2rem 0 2rem;

  #sfu-slider-title {
    color: $dark-gray;
    text-align: center;

    #sfu-slider-caption {
      height: 50px;
    }
  }
}

button {
  color: $primary;
}

.slick-prev .slick-prev-icon:before {
  color: $white;
  font-family: "bootstrap-icons";
  background-color: $dark-gray;
  font-weight: 1000;
  padding: 5px;
  content: "\F230";
}

.slick-prev {
  z-index: 1;
  left: 10%;

  @media (max-width: 1300px) {
    left: 5%;
  }
}

.slick-next .slick-next-icon:before {
  color: $white;
  font-family: "bootstrap-icons";
  background-color: $dark-gray;
  font-weight: 1000;
  padding: 5px;
  content: "\F234";
}

.slick-next {
  right: 10%;

  @media (max-width: 1300px) {
    right: 5%;
  }
}

.slick-pause-icon {
  display: none;
}