/*
    Default customizations
*/
a {
    color: $primary;

    &:hover {
        color: $primary;
        text-decoration: none !important;
        font-weight: 800;
    }
}

a.list-group-item, a.h5 {
    text-decoration: none !important;
}

.nav-link {
    color: $primary;
    background-color: $white;

    &:hover, &:focus {
        color: $white;
        background-color: $primary;
    }
}

li.nav-item.dropdown:hover {
    background-color: $white !important;
}

.nav-tabs .nav-link {
    color: $primary;
    font-weight: 600;

    &:hover {
        font-weight: 600;
        color: $white;
        border: $white;
        border-top-right-radius: 0.25rem !important;
        border-top-left-radius: 0.25rem !important;
    }
}

.nav-tabs .nav-link.active {
    font-weight: 600;
    color: $white !important;
    background-color: $primary !important;
    border-top-right-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
}

.w-0 {
    width: 100%;
}

body {
    font-family: 'Barlow', serif;
    background-color: $background-gray;
}

.page-link, .page-link:hover {
    color: $primary !important;
}

.atom-btn-white {
    background-color: $background-gray;
    border: none;
    border-radius: 0;

    &:active {
        color: $primary;
    }

    .fa-times {
        color: $primary;
        font-size: 0.75rem;
    }
}

.atom-btn-white.active-primary.active {
    color: $white !important;
}

a.h5 {
    color: $secondary;
    font-size: 1.5rem;
    font-weight: 600;
}

.text-muted {
    color: $text-gray;
}

.navbar-brand {
    font-size: 18px;
}

.fas, .text-wrap {
    color: $secondary;
}

.card {
    border: none;

    .list-group-item {
        border: none;
    }
}

.card-body {
    padding: 0.5rem !important
}

#settings-menu .list-group-item {
    padding-left: 0.5rem;
}

.actions {
    background-color: $white;
}

.adv-btn {
    font-size: 1rem;
    border-radius: 0 !important;
    background-color: $primary !important;
    color: $white !important;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .bi {
        padding: 0 0.5rem;
    }

    &::after {
        display: none;
    }

    &:focus, &:hover {
        background-color: $background-gray;
        color: $secondary;
    }
}

.fa-angle-double-down {
    font-weight: 100;
    padding: 0 0.5rem;
    font-size: 0.75rem;
}

#sidebar .accordion-header .accordion-button, #main-column .accordion-button {
    color: $secondary;
    font-size: 1rem;
    border-radius: 0 !important;
    padding: 0.5rem;
}

#sidebar .accordion-button:not(.collapsed) {
    background-color: $background-gray;

    &::after {
        content: "\F282";
        font-family: "bootstrap-icons";
        font-size: 1rem;
        color: $text-gray;
        background-image: none !important;
        transform: rotate(-180deg);
    }
}

.accordion-button::after {
    content: "\F282";
    font-family: "bootstrap-icons";
    color: $white;
    background-image: none;
    transition: transform 0.2s ease-in-out;
}

.list-group-flush > .list-group-item {
    padding: 0.25rem 0.5rem;
}

#main-column h1 {
    font-weight: 900;
    color: $primary;
}

h1, h2 {
    font-family: 'Barlow Condensed', serif;
    text-transform: uppercase;
}

.alert-info, .alert-warning {
    background-color: $white;
}

/*
    Background Customizations
*/
#popular-this-week, #recently-added {
    background-color: $background-gray !important;
}

/*
    Homepage Customizations
*/
.list-group-item {
    padding: 0.25rem 0;
    color: $primary;
    background-color: $background-gray;

    &:hover {
        color: $primary;
        text-decoration: underline;
    }
}

#popular strong {
    font-weight: 600;
}

#homepage-about {
    background-color: $white;
    padding: 1rem 3.5rem !important;
    margin-top: 3rem;

    @media (max-width: 1300px) {
        margin-top: 0;
    }
}

#homepage-edit-btn {
    margin-left: 1.5rem;
}

#homepage-statics {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;

    .static-btn {
        flex: 1;
        margin: 0.25rem;
        padding: 0.5rem 0;
        text-align: center;

        &:hover a, &:focus a {
            color: $white;
        }
    }
}

/*
    Description Customizations
*/
.atom-section-header {
    background-color: $gray;
    font-weight: 900;
}

#context-menu {
    .atom-btn-white, a.atom-btn-white {
        border: none;
    }
}


#content {
    border: 0;
    background-color: $white;

    ul {
        margin: 0 !important;
        padding: 0 !important;

        li {
            list-style: none;
        }
    }

    .text-primary {
        background-color: $gray;
        color: $white !important;
        font-size: 1.2rem !important;
    }

    span.text-primary {
        color: $primary !important;
        background-color: $white;
        font-size: 1rem !important;
    }
}

.btn, .nav-item, .atom-btn-secondary {
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 0;
    color: $primary !important;
    font-weight: 800;
    font-family: 'Barlow Condensed', sans-serif;
    text-transform: uppercase;

    .fas {
        color: $red !important;
    }

    &:hover, &:focus {
        background-color: $red;
        color: $white !important;

        .fas {
            color: $white !important;
        }
    }
}

#btn-to-link {
    background-color: inherit;
    border: none;

    &:hover {
        font-weight: 800;

        .fa-paperclip, .fa-file-pdf::before {
            color: $primary;
        }
    }
}

.atom-icon-link:hover {
    font-weight: 800;
}

.nav-item {
    border: none !important;
}

.repository-logo-text h2, .repository-logo {
    max-width: 100% !important;
}

// chevrons
.accordion-button::after {
    background-image: none !important;
    color: $text-gray;
    font-weight: 800;
    font-size: 1.2rem;
}

/*
    Search Customizations
*/
.accordion-button {
    font-weight: 600;

    .bi-chevron-double-down::before {
        font-weight: 600 !important;
    }
}