// SFU colours
$dark-gray: #6d6f71;
$gray: #c5c9c9;
$text-gray: #888888;
$red: #CC0633;
$background-gray: #f8f8f8;
$white: #ffffff;

$primary: $red;
$secondary: $dark-gray;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
)