#sfu-top-bar {
    height: 20px;
    color: $dark-gray;
    justify-content: start;
    display: flex;
    font-family: 'Barlow Condensed', serif;
    text-transform: uppercase;
    font-weight: 900;
    padding: 0;

    .span {
        width: 1300px;
        margin: auto;
    }

    #header-text {
        justify-content: center;
        align-items: center;
    }

    #sfu-socials {
        display: flex;
        justify-content: end;
        align-items: end;

        #socials {
            display: block;

            a {
                color: $dark-gray;
            }

            a:hover, i:hover {
                color: $red;
            }
        }
    }

    a {
        color: $dark-gray;

        &:hover {
            color: $primary;
        }
    }

    @media (max-width: 1225px) {
        font-size: 0.75rem;
    }
}

#sfu-logo {
    height: 64px;
}

#search-box-input {
    border-color: $primary;
    max-width: 336px;
}

#site-title {
    width: fit-content;
}

.dropdown-menu .dropdown-header {
    font-size: 1.25rem;
    font-weight: 800;
}

#nav-bg {
    background-color: $white;
    width: 100%;

    #header-navbar {
        background-color: $white;
        margin-top: 1rem !important;
        max-width: 1300px;
        margin: auto;

        #sfu-title {
            margin-top: 1rem;

            @media (max-width: 1193px) {
                display: none;
            }
        }

        #site-nav {
            display: flex;
            flex-direction: row-reverse;
            flex: 1;
            padding-bottom: 0;

            .navbar-toggler {
                border-radius: 0;
                border: 1px solid $primary;

                .navbar-toggler-icon {
                    svg {
                        display: none;
                        color: $primary;

                        &:hover {
                            color: $white;
                        }
                    }

                    &::after {
                        content: " \F479";
                        font-family: "bootstrap-icons";
                        color: $primary;
                        font-size: 2rem;
                        font-weight: 500;
                    }
                }
            }

            .navbar-toggler:hover {
                .navbar-toggler-icon:hover::after {
                    color: $white;
                }

                .navbar-toggler-icon:visited::after {
                    color: $white;
                }
            }

            .dropdown-item {
                color: $primary;

                &:hover {
                    color: $primary;
                    text-decoration: underline;
                }
            }
        }
    }
}